import React, { Component } from 'react';
import * as style from '../magazine/featuredVideo.module.scss';
import VideoBackground from '../basic/videoBackground';
import VideoPlayer from '../ui/videoPlayer';
import LazyImage from '../ui/lazyImage';
import CircleButton from '../ui/circleButton';
import Modal from '../ui/modal';

class FeaturedVideo extends Component {
	constructor() {
		super();
		this.state = {
			playVideo: false,
			isClosing: false,
		};
	}
	playVideo = () => {
		this.setState({
			playVideo: true,
		});
	}
	
	hideVideo = () => {
		this.setState({ playVideo: false, isClosing: true }, () => {
			setTimeout(() => {
				this.setState({ isClosing: false });
			}, 1000);
		});
	}
	
	render() {
		const {
			video
		} = this.props;
		const imgSrc = video.image ? video.image.sizes.standard_md : '';
		return (
			<div className={style.featuredVideo}>
				<div style={{position: 'relative'}} onClick={this.playVideo} className={style.featuredVideoWrapper}>
					{video.video_background ? (
						<div>
							<VideoBackground videoStyle="normal" src={video.video_background.url} />
							<div className={style.play}>
								<CircleButton type="play" />
							</div>
						</div>
					) : (
						<div className={style.coverImageWrapper}>
							<div className={style.coverImage}>
								<LazyImage
									src={imgSrc}
									alt={video.image.alt_text}
									hasRevealer={false}
									revealed={true}
									width={600}
									height={450}
									fitSize={true}
								/>
							</div>
							<div className={style.play}>
								<CircleButton type="play" />
							</div>
						</div>
					)}
				</div>
				{video.article &&
					<div className={style.title}>
						<a href={video.article.path}>{video.article.title}&nbsp;<span className={style.angle}>&#8250;</span></a>
					</div>
				}
				{(this.state.playVideo || this.state.isClosing) && (
				<Modal
					toggleOpen={this.hideVideo}
					exiting={this.state.isClosing}
					basicFade
					fullScreen
				>
					<div className={style.modalContentWrapper}>
						<div className={style.videoWrapper}>
							<VideoPlayer
								imgSrc={imgSrc}
								videoId={video.video.id}
								videoUrl={video.video.url}
								fullScreen={false} />
						</div>
						<div className={style.modalTitle}>
							<a href={video.article.path}>{video.article.title}&nbsp;<span className={style.angle}>&#8250;</span></a>
						</div>
					</div>
				</Modal>
				)}
			</div>
		)
	}
}

export default FeaturedVideo;
