// extracted by mini-css-extract-plugin
export var featuredVideo = "featuredVideo-module--featuredVideo--UOIsb";
export var featuredVideoWrapper = "featuredVideo-module--featuredVideoWrapper--F+TV4";
export var coverImageWrapper = "featuredVideo-module--coverImageWrapper--ukdsl";
export var play = "featuredVideo-module--play--YJTR1";
export var coverImage = "featuredVideo-module--coverImage--1h2Y4";
export var title = "featuredVideo-module--title---toPS";
export var angle = "featuredVideo-module--angle--+vomp";
export var modalContentWrapper = "featuredVideo-module--modalContentWrapper--p1XSV";
export var modalTitle = "featuredVideo-module--modalTitle--10tco";
export var videoWrapper = "featuredVideo-module--videoWrapper--vcUmb";
export var slideUp = "featuredVideo-module--slideUp--IpFoL";
export var slideUpLarge = "featuredVideo-module--slideUpLarge--M+cLh";