import React, { Component } from 'react';
import * as style from '../magazine/externalArticleList.module.scss';
import ExternalArticle from './externalArticle'
import translations from '../../utils/translations';

class ExternalArticleList extends Component {
	render() {
		const {
			articleList,
		} = this.props;
		return (
			<div className={style.externalArticleList}>
				<div className={style.header}>
					{translations.t('what we\'re reading')}
				</div>
				<div className={style.innerWrapper}>
					{articleList.map((article, i) => (
						<ExternalArticle article={article} showDivider={articleList.length != (i + 1)} key={`external${i}`} />
					))}
					<div className={style.spacerWrapper}>
						<div className={style.spacer} />
					</div>
				</div>
			</div>
		)
	}
}

export default ExternalArticleList;
