import React, { Component } from 'react';
import * as style from '../magazine/externalArticle.module.scss';

class ExternalArticle extends Component {
	render() {
		const {
			article,
			showDivider
		} = this.props;
		
		return (
			<div className={style.externalArticle}>
				<div className={style.externalArticleContainer}>
					<div className={style.externalArticleWrapper}>
						<a href={article.url} target="_blank">
							<div className={style.headline}>{article.headline}</div>
							<div className={style.title}>{article.title}&nbsp;<span className={style.angle}>&#8250;</span></div>
						</a>
					</div>
					{showDivider &&
						<div className={style.divider} />
					}
				</div>
			</div>
		)
	}
}

export default ExternalArticle;
