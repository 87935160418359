import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import CircleButton from './circleButton';
import * as style from './modal.module.scss';
import translations from '../../utils/translations';

class Modal extends Component {
  constructor() {
    super();
    this.parent = document.querySelector('.sitewrap');
  }
  handleKey = e => {
    if (e.key === 'Escape') {
      this.props.toggleOpen();
    }
  };
  componentDidMount() {
    window.addEventListener('keydown', this.handleKey);
  }
  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKey);
  }

  render() {
    this.parent = document.querySelector('.sitewrap');
    if(this.parent) {
      return ReactDOM.createPortal(
        <section
          className={`${style.modalWrap} ${
            this.props.exiting ? style.exiting : ''
          } ${this.props.fullScreen ? style.fullScreen : ''}`}
        >
          <div className={style.modalInner}>
            <div className={style.closeWrap}>
              <CircleButton
                type="close"
                handleClick={this.props.toggleOpen}
                text={translations.t('Close')}
              />
            </div>
            <div
              className={`${style.contentWrap} ${
                this.props.basicFade ? style.basicFade : ''
              }`}
            >
              {this.props.children}
            </div>
          </div>
          <div className={style.bk} onClick={this.props.toggleOpen} />
        </section>,
        this.parent
      );
    }
    return null;
  }
}

export default Modal;
