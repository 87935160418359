// extracted by mini-css-extract-plugin
export var articleSummary = "horizontalArticleSummary-module--articleSummary--uJcPT";
export var image = "horizontalArticleSummary-module--image--InLQp";
export var articleDetails = "horizontalArticleSummary-module--articleDetails--ysX9R";
export var relatedChallenge = "horizontalArticleSummary-module--relatedChallenge--kHWzd";
export var category = "horizontalArticleSummary-module--category--brWv-";
export var author = "horizontalArticleSummary-module--author--sVKl-";
export var title = "horizontalArticleSummary-module--title--nhdpR";
export var angle = "horizontalArticleSummary-module--angle--4XUWI";
export var slideUp = "horizontalArticleSummary-module--slideUp--xb-Fa";
export var slideUpLarge = "horizontalArticleSummary-module--slideUpLarge--kO783";